import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project-imports
// import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';
// import { useSelector } from '../../redux';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }) => {
  const { isLoggedIn, state } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  // const { menu } = useSelector((state) => state.menu);

  useEffect(() => {
    // console.log(location?.state?.from);
    // const menu = localStorage.getItem('persist:root');

    if (isLoggedIn) {
      // if (isLoggedIn) {
      //   navigate(location?.state?.from && location?.state?.from, {
      //     state: {
      //       from: ''
      //     },
      //     replace: true
      //   });
      // }
      const redirectPath = location?.state?.from?.pathname || '/dashboard';
      navigate(redirectPath, { replace: true });
    }
    // console.log(location);
  }, [isLoggedIn, navigate, location?.state?.from?.pathname, state]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
