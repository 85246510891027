/* eslint-disable react/prop-types */
import { Chip } from '@mui/material';
import * as yup from 'yup';

// chip css
import '../utils/custom.css';
import { inrCurrency } from './utilConstant';

// Autocomplete data

// Add form values
const formAllValues = {
  investor_id: 0,
  value: 'All',
  fd_id: 0,
  ifa_id: 0,
  maturity_action_id: 0,
  payout_method_id: 'C',
  investment_amount: null,
  years: 0,
  months: 0,
  days: 0,
  interest_rate: '0',
  aggrigated_interest: 0,
  maturity_amount: 0
};
const validationSchema = yup.object().shape({
  investor_id: yup.number(),
  fd_id: yup.number(),
  ifa_id: yup.number(),
  maturity_action_id: yup.number(),
  payout_method_id: yup.string(),
  investment_amount: yup.number().min(1, 'Maximum tenure must be greater than or equal to 1').required('Investment Amount is required'),
  years: yup.number(),
  months: yup.number(),
  days: yup.number(),
  interest_rate: yup.number(),
  aggrigated_interest: yup.number(),
  maturity_amount: yup.number()
});
// Scheme Form values
const schemeValues = {
  fd_name: '',
  logo_url: '',
  rate_of_interest: '',
  rate_of_interest_1lakh: '',
  tenure: '',
  maturity_id: 1,
  investment_amount: 0
};
const schemeValidation = yup.object().shape({});
// Investor Values
const investorValues = {
  is_permanent_address_correspondent: 0,
  investor: {
    investor_name: '',
    pan_no: '',
    mobile_no: '',
    is_senior_citizen: 1,
    birth_date: new Date(),
    place_of_birth: '',
    is_married: 1,
    is_indian_resident: 1
  },
  // Investor Address
  investor_address: {
    address_line_1: '',
    address_line_2: '',
    pincode: '',
    city: ''
  },
  correspondent_address: {
    address_line_1: '',
    address_line_2: '',
    pincode: '',
    city: ''
  },
  professional_details: {
    occupation_id: 1,
    annual_income_id: 1,
    income_source_id: 1
  }
};
const investorValidationSchema = yup.object().shape({
  is_permanent_address_correspondent: yup.number(),
  investor: yup.object().shape({
    investor_name: yup.string().required('Investor Name is required'),
    pan_no: yup
      .string()
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN format')
      .required('Pan number is required'),
    mobile_no: yup.string().required('Mobile number is required'),
    is_senior_citizen: yup.number().required('Investor type is required'),
    birth_date: yup.date(),
    place_of_birth: yup.string().required('Place of Birth is required'),
    is_married: yup.number(),
    is_indian_resident: yup.number()
  }),
  investor_address: yup.object().shape({
    address_line_1: yup.string().required('Address Line is required'),
    address_line_2: yup.string().required('Address Line 2 is required'),
    pincode: yup.string().required('Pin Code is required'),
    city: yup.string().required('City is required')
  }),
  correspondent_address: yup.object().shape({
    address_line_1: yup.string().when('$is_permanent_address_correspondent', {
      is: 0,
      then: () => yup.string().required('Address Line 1 is required'),
      otherwise: () => yup.string().optional()
    }),
    address_line_2: yup.string().when('$is_permanent_address_correspondent', {
      is: 0,
      then: () => yup.string().required('Address Line 2 is required'),
      otherwise: () => yup.string().optional()
    }),
    pincode: yup.string().when('$is_permanent_address_correspondent', {
      is: 0,
      then: () => yup.string().required('Pin Code is required'),
      otherwise: () => yup.string().optional()
    }),
    city: yup.string().when('$is_permanent_address_correspondent', {
      is: 0,
      then: () => yup.string().required('City is required'),
      otherwise: () => yup.string().optional()
    })
  }),
  professional_details: yup.object().shape({
    occupation_id: yup.number(),
    annual_income_id: yup.number(),
    income_source_id: yup.number()
  })
});

// Table Columns
const VisibleColumn = [];
const StatusCell = ({ value }) => {
  switch (value) {
    case 'canceled':
      return <Chip sx={{ fontSize: '0.75rem', border: 'none' }} color="error" label="Cancelled" size="medium" variant="outlined" />;
    default:
      return (
        <Chip
          sx={{ fontSize: '0.75rem', backgroundColor: 'transparent !important', border: 'none' }}
          color="info"
          label={value}
          size="medium"
          variant="light"
        />
      );
  }
};

const tableColumns = [
  //logo
  {
    Header: 'Logo',
    accessor: 'logo_url',
    customCell: ({ value }) => {
      return (
        <div style={{ width: '120px', height: '65px', borderRadius: '10px' }}>
          <img
            src={value}
            alt="logo"
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              objectFit: 'contain',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
              padding: '4px'
            }}
          />
        </div>
      );
    }
  },
  {
    Header: 'Investor ID',
    accessor: 'investor_id'
  },
  {
    Header: 'Investor Name',
    accessor: 'investor_name',
    minWidth: 150
  },
  {
    Header: 'IFA Name',
    accessor: 'ifa_name',
    minWidth: 150
  },
  {
    Header: 'Issuer Reference Number',
    accessor: 'issuer_ref_number'
  },
  {
    Header: 'Application Id',
    accessor: 'application_id'
  },
  {
    Header: 'Principal amount',
    accessor: 'investment_amount',
    customCell: ({ value }) => {
      return <span>{inrCurrency(value)}</span>;
    }
  },

  {
    Header: 'Tenure',
    accessor: 'tenure_selected',
    minWidth: 150
  },
  {
    Header: 'Rate of Interest (%)',
    accessor: 'rate_of_interest'
  },
  {
    Header: 'Payout Type',
    accessor: 'fd_payout_method'
  },
  {
    Header: 'Total Interest',
    accessor: 'maturity_amount'
  },
  {
    Header: 'Reg. date',
    accessor: 'created_on',
    minWidth: 150
  },
  {
    Header: 'Status',
    accessor: 'fd_status',
    customCell: StatusCell
  },
  {
    Header: 'Remark',
    accessor: 'remark'
  }
  // {
  //   Header: 'Folio ID',
  //   accessor: 'folio_id'
  // },

  // {
  //   Header: 'Master ID',
  //   accessor: 'master_id'
  // },

  // {
  //   Header: 'FD Name',
  //   accessor: 'fd_name',
  //   minWidth: 150
  // },

  // {
  //   Header: 'Interest Earned',
  //   accessor: 'interest_earned',
  //   customCell: ({ value }) => {
  //     return <span>{inrCurrency(value)}</span>;
  //   }
  // },
];

export {
  formAllValues,
  validationSchema,
  schemeValues,
  schemeValidation,
  investorValues,
  investorValidationSchema,
  tableColumns,
  VisibleColumn
};

// {
//   Header: 'Description',
//   accessor: 'description',
//   minWidth: 250,
//   customCell: ({ value }) => {
//     const parsedValue = parseAndModifyValue(value);

//     return <span>{parsedValue}</span>;
//   }
// },
