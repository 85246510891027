/* eslint-disable react/prop-types */
import { act, useEffect, useMemo, useState } from 'react';

// material-ui
import { Divider, Box, Card, Grid, CardContent, CardHeader, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQuery } from 'react-query';

// project-imports
import Select from 'react-select';

import MultiTable from '../multiTable/multiTable';
import { tableColumns, VisibleColumn } from 'constant/commisionValidation';

// third-party
import { Formik } from 'formik';
import * as yup from 'yup';

// assets
import { GetActiveIssuerData } from 'hooks/issuer/issuer';
import { GetCommission } from 'hooks/commission/Commission';
import { selectCustomStyles } from 'utils/reactSelect';
import '../faq/faq.css';

function Commission() {
  // Main data state to hold the list of issuers
  //   const [issuerData, setIssuerData] = useState([]);
  const [allCommission, setAllCommission] = useState([]);
  const [activeIssuers, setActiveIssuers] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [fetchingCommission, setFetchingCommission] = useState(false);
  // Editing States
  //   const [isEditing, setIsEditing] = useState(false); // State to track if editing mode is active
  //   const [isIssuerActive, setIssuerActive] = useState(); // State to track if the issuer is active or not active
  // Form Visibility
  const [showTable, setShowTable] = useState(true); // State to hold form input values

  // Theme
  const theme = useTheme();
  // Theme
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  // Functions
  // Editing States
  const setEditing = (value) => {
    setFormValues(value);
  };

  // Form Visibility
  const changeTableVisibility = () => {
    setExpanded('');
    setShowTable(!showTable);
  };

  // Table Columns

  const columns = useMemo(() => tableColumns, []);

  const getCommissionForIssuer = async (id) => {
    const payload = {
      method_name: 'get_commission_on_issuer',
      issuer_id: id
    };
    try {
      setFetchingCommission(true);
      const commissions = await GetCommission(payload);

      console.log(commissions);

      setAllCommission(commissions);
    } catch (err) {
      console.log(err);
    } finally {
      setFetchingCommission(false);
    }
  };

  // Query for fetching issuer data // Main Data
  const {
    isPending: isActiveIssuerPending,
    isFetching,
    error: activeIssuerError
  } = useQuery({
    queryKey: ['activeIssuerData'], // Unique key for the query
    refetchOnWindowFocus: false, // Disable refetch on window focus
    keepPreviousData: true, // Keep previous data when refetching
    queryFn: () => {
      const payload = {
        method_name: 'getall_isactive'
      };
      return GetActiveIssuerData(payload);
    },
    onSuccess: (data) => {
      console.log(data);

      const mapData = data?.map((issuer) => {
        return { label: issuer?.issuer_name, value: issuer?.issuer_id };
      });
      setActiveIssuers(mapData);

      //   getCommissionForIssuer(mapData[0]?.value);
    }
  });

  useEffect(() => {
    if (!(activeIssuers?.length > 0)) return;
    setSelectedIssuer(activeIssuers[0]);
    getCommissionForIssuer(activeIssuers[0]?.value);
  }, [activeIssuers]); // Added activeIssuers to the dependency array

  return (
    <>
      {showTable && (
        <Formik
          enableReinitialize
          initialValues={{
            issuer_id: null
          }}
          validationSchema={yup.object({
            issuer_id: yup.object()
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values);
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Box
              component="form"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
              sx={{ width: '100%' }}
            >
              <Card
                sx={{
                  position: 'relative',
                  border: '1px solid',
                  borderRadius: 1.5,
                  borderColor: theme.palette.divider,
                  overflow: 'visible'
                }}
              >
                <Grid container spacing={2} sx={{ alignItems: 'center !important' }}>
                  <Grid item md={3} sm={3} xs={4}>
                    <CardHeader
                      sx={{
                        p: 2,
                        '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
                      }}
                      titleTypographyProps={{ variant: 'subtitle1' }}
                      title="Commission"
                    />
                  </Grid>
                  <Grid item md={7} sm={7} xs={6}></Grid>
                </Grid>

                <Divider />

                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                      <Select
                        className="multi_select"
                        name="issuer_id"
                        options={activeIssuers}
                        onChange={(e) => {
                          console.log('valuessssssssssssssss is ', e.value);
                          //   {
                          //     "label": "Mahindra Finance",
                          //     "value": 2
                          // }
                          setFieldValue('issuer_id', e);
                          setSelectedIssuer(e);
                          getCommissionForIssuer(e.value);
                        }}
                        styles={selectCustomStyles}
                        placeholder="Select Issuer"
                        // value={values?.issuer_id}
                        value={selectedIssuer}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <MultiTable
                        columns={columns}
                        data={allCommission}
                        formValues={[]}
                        formValueFields={[]}
                        validationSchema={{}}
                        changeTableVisibility={() => {}}
                        setEditing={() => {}}
                        getOneItem={() => {}}
                        deleteOneItem={() => {}}
                        deletingItem={false}
                        setDeletingItem={() => {}}
                        setSearchData={() => {}}
                        tableDataRefetch={() => {}}
                        setActiveEditing={() => {}}
                        VisibleColumn={VisibleColumn}
                        isFetching={fetchingCommission}
                        hideActions={true}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}
        </Formik>
      )}
    </>
  );
}

export default Commission;
