/* eslint-disable react/prop-types */
// material-ui
import { Divider, Grid, List, ListItem, ListItemIcon, Stack, Typography, Box } from '@mui/material';

// project-imports
import MainCard from '../../organisms/mainCard/MainCard';
import Avatar from '../../../helpers/@extended/Avatar';

// assets
import { CallCalling, Gps, Sms } from 'iconsax-react';
import getFallbackValue from 'constant/valueFallbacks';
import formatMobileNumber from 'constant/numberFormatters';

const avatarImage = require.context('assets/images/users', true);

// ==============================|| ACCOUNT PROFILE - BASIC ||============================== //

const TabPortfolio = (props) => {
  const handleError = () => {
    // Fallback to default image if the src fails to load
    setImgSrc(gender?.toLowerCase() === 'male' ? './avatar-1.png' : './default.png');
  };
  return (
    <MainCard contentSX={{ paddingBottom: '24px !important' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={2.5} alignItems="center">
            {/* TODO : fix the url for the croptedimage */}
            <Avatar alt="Avatar 1" size="xl" src={`data:image/jpeg;base64,${props.values?.personal_info?.profile_image}`} />
            <Stack spacing={0.5} alignItems="center">
              <Typography variant="h5">{props.values.investor.investor_name || 'Name Missing'}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: { sm: '8px' } }}>
                <Typography sx={{ wordBreak: 'break-word' }}>Investor ID: </Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{props.values.investor.investor_id}</Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
          <List component="nav" aria-label="main mailbox folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
            <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: { sm: '8px', lg: '12px' } }}>
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <Sms size={18} />
                </ListItemIcon>
                <Typography sx={{ wordBreak: 'break-word' }}>{props.values.investor.email_id}</Typography>
              </Box>
            </ListItem>
            {/* ================ */}
            <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: { sm: '8px', lg: '12px' } }}>
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <CallCalling size={18} />
                </ListItemIcon>
                <Typography align="right">(+91) {formatMobileNumber(props.values.investor.mobile_no)}</Typography>
              </Box>
            </ListItem>
            <ListItem sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: { sm: '8px', lg: '12px' } }}>
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <Gps size={18} />
                </ListItemIcon>
                <Typography align="right">{getFallbackValue(props.values.investor.place_of_birth)}</Typography>
              </Box>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default TabPortfolio;
