import * as yup from 'yup';
import { inrCurrency } from './utilConstant';

const VisibleColumn = [];

const tableColumns = [
  {
    Header: 'Selected Tenure',
    accessor: 'tenure_selected'
  },
  // {
  //   Header: 'Commission Amount',
  //   accessor: 'commission_amount',
  //   customCell: ({ value }) => {
  //     return <span>₹ {value.toFixed(2)}</span>;
  //   }
  // },
  {
    Header: 'Commission Amount',
    accessor: 'commission_amount',
    customCell: ({ value }) => {
      const numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        return <span>-</span>;
      }

      return <span>₹ {numericValue.toFixed(2)}</span>;
    }
  },
  {
    Header: 'Total Investment',
    accessor: 'total_investment',
    customCell: ({ value }) => {
      return <span>{inrCurrency(value)}</span>;
    }
  }
];

export { tableColumns, VisibleColumn };
