import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'helpers/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Issuer from '../issuer/issuer';
import FixDeposit from '../fixedDeposit/fixedDeposit';
import Investor from '../investor/investor';
import Investment from '../transaction/investment';
import UserList from '../userList/userList';
import Role from '../userList/role';
import Dashboard from '../dashboard/dashboard';
import FDReport from '../reports/fdReport';
import IssuerReport from '../reports/issuerReport';
import FAQ from '../faq/faq';
import FdCategory from '../fdCategory/fdCategory';

// Product
const Product = Loadable(lazy(() => import('components/pages/productType/productType')));
// User
const UserProfile = Loadable(lazy(() => import('components/pages/userProfile/user')));
const UserTabPersonal = Loadable(lazy(() => import('../../organisms/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('../../organisms/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('../../organisms/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('../../organisms/user/TabSettings')));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          path: 'investor',
          element: <Investor />
        },
        {
          path: 'transaction',
          children: [
            {
              path: 'investment',
              element: <Investment />
            }
          ]
        },
        {
          path: 'product',
          children: [
            {
              path: 'add_product',
              element: <></>
            },
            {
              path: 'product',
              element: <Product />
            },
            {
              path: 'fixed_deposit',
              element: <FixDeposit />
            }
          ]
        },
        {
          path: 'issuer',
          element: <Issuer />
        },
        {
          path: 'master',
          children: [
            {
              path: 'fd_category',
              element: <FdCategory />
            }
          ]
        },
        {
          path: 'user_master',
          children: [
            {
              path: 'role',
              element: <Role />
            },
            {
              path: 'users',
              element: <UserList />
            }
          ]
        },
        {
          path: 'user',
          element: <UserProfile />,
          children: [
            {
              path: 'personal',
              element: <UserTabPersonal />
            },
            {
              path: 'payment',
              element: <UserTabPayment />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            },
            {
              path: 'settings',
              element: <UserTabSettings />
            }
          ]
        },
        {
          path: 'report',
          children: [
            {
              path: 'fd_report',
              element: <FDReport />
            },
            {
              path: 'issuer_report',
              element: <IssuerReport />
            }
          ]
        },
        {
          path: 'faq',
          element: <FAQ />
        }
      ]
    }
  ]
};

export default MainRoutes;
