/* eslint-disable react/prop-types */

// material-ui
import { Box, Grid, Stack, Typography } from '@mui/material';

// project-imports
import MainCard from 'components/organisms/mainCard/MainCard';

// assets
import SwitchBalanace from './SwitchBalance';
import { Chart, UserAdd, Wallet3 } from 'iconsax-react';
import { inrCurrency } from 'constant/utilConstant';
import { getFallbackImage } from 'utils/imageFallback';

// ===========================|| STATISTICS - WALLET PROFILE ||=========================== //

const WalletProfile = ({ logoURL, fdName, investorCount, fdInvestment, netIvestment }) => {
  return (
    <MainCard contentSX={{ padding: '16px !important' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MainCard content={false}>
            <Box sx={{ p: '10px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <div style={{ width: '120px', height: '65px' }}>
                    <img
                      src={logoURL}
                      onError={(e) => (e.target.src = getFallbackImage(e.target.src))}
                      alt="logoURL"
                      style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        objectFit: 'contain',
                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                        padding: '8px'
                      }}
                    />
                  </div>
                  <Stack>
                    <Typography variant="h6" fontWeight={700}>
                      {fdName}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </MainCard>
        </Grid>
        <Grid item xs={4}>
          <SwitchBalanace name="AUM" value={inrCurrency(fdInvestment)} mainIcon={<Wallet3 color="#fff" />} />
        </Grid>
        <Grid item xs={4}>
          <SwitchBalanace name="Net Volume" value={inrCurrency(netIvestment)} mainIcon={<Chart color="#fff" />} />
        </Grid>
        <Grid item xs={4}>
          <SwitchBalanace name="Investors" value={investorCount} mainIcon={<UserAdd color="#fff" />} />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default WalletProfile;
