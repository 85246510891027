import { Grid, useTheme } from '@mui/material';
import Loader from 'components/atoms/loader/Loader';
import { inrCurrency } from 'constant/utilConstant';
import EcommerceMetrix from 'helpers/cards/statistics/EcommerceMetrix';
import { GetDashboardData } from 'hooks/dashboard/dashboard';
import { MoneyAdd, MoneyChange, UserAdd, UserTick } from 'iconsax-react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import WalletProfile from 'sections/widgets/statistics/WalletProfile';
import Error500 from '../maintenance/error/500';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState([]);
  const theme = useTheme();

  // Query for fetching dashboard data // Main Data
  const { isFetching } = useQuery({
    queryKey: ['dashboardData'],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: () => {
      const payload = {
        method_name: 'getdashboard'
      };
      return GetDashboardData(payload);
    },
    onSuccess: (data) => {
      console.log(data);

      setDashboardData(data);
    },
    onError: (err) => {
      console.log(err);
    }
  });

  if (isFetching) return <Loader />;

  if (dashboardData.length === 0) return <Error500 />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6} md={3}>
        <EcommerceMetrix
          primary="Total Investors"
          secondary={dashboardData.investor_details.investor_count}
          color={theme.palette.primary.main}
          iconPrimary={UserAdd}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <EcommerceMetrix
          primary="Registered Users"
          secondary={dashboardData.investor_details.registered_user}
          color={theme.palette.info.main}
          iconPrimary={UserTick}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <EcommerceMetrix
          primary="Total AUM"
          secondary={inrCurrency(dashboardData.investor_details.total_investments)}
          color={theme.palette.success.main}
          iconPrimary={MoneyAdd}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <EcommerceMetrix
          primary="Net Volume"
          secondary={inrCurrency(dashboardData.investor_details.net_investment)}
          color={theme.palette.warning.main}
          iconPrimary={MoneyChange}
        />
      </Grid>

      {dashboardData.fd_details.map((fd, id) => {
        return (
          <Grid key={id} item xs={12} md={6}>
            <WalletProfile
              logoURL={fd.logo_url}
              fdName={fd.fd_name}
              investorCount={fd.investor_count}
              fdInvestment={fd.investment_on_fd}
              netIvestment={fd.net_investment}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default Dashboard;
