export const selectCustomStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3da866' : state.isFocused ? '#f5f5f5' : provided.backgroundColor,
    color: state.isSelected ? 'white' : 'black'
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#3da866' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #3da866' : provided.boxShadow,
    '&:hover': {
      borderColor: '#3da866'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3da866' : provided.backgroundColor,
    color: state.isSelected ? 'white' : 'black'
  })
};
